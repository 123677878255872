<template>
  <div class="container690">
    <iq-card class="other_donate">
      <h3>Select Organization</h3>
      <!-- <el-select class="w-100" v-model="organization_id" filterable remote reserve-keyword
        :remote-method="organizationSearch" placeholder="Enter keyword search organization">
        <el-option v-for="item in orgList" :key="item.id" :label="item.name" :value="item.id">
        </el-option>
      </el-select> -->
      <OrganizationSel @getOrganizationParams="getOrganizationParams" />
    </iq-card>
    <SelectAmount @getAmountFunc="getAmount" />
    <InformationForm ref="inForm" @getInform="getInform" />
    <Pay @donate="donate" />
  </div>
</template>

<script>
  import SelectAmount from "./components/SelectAmount";
  import InformationForm from "./components/InformationForm";
  import OrganizationSel from "@/components/ComComponents/OrganazationSel";
  import Pay from "./components/Pay";
  export default {
    data() {
      return {
        amount: "",
        donated_type: 1,
        form: {},
        organization_id: '',
        orgList: [],
        donor_organization_param: {}
      };
    },

    components: { SelectAmount, InformationForm, Pay, OrganizationSel },

    methods: {
      // organizationSearch(query) {
      //   this.$http
      //     .organizationSearch({ organization_name: query })
      //     .then((res) => {
      //       if (res.status == 200) {
      //         this.orgList = res.data;
      //       }
      //     });
      // },

      // 选择组织
      getOrganizationParams(data) {
        let OrganData = JSON.parse(JSON.stringify(data));
        this.donor_organization_param = OrganData
        let level = OrganData.level.id;
        switch (level) {
          case 0: {
            // jci
            this.organization_id = OrganData.jci.id;
            break;
          }
          case 4: {
            // area
            this.organization_id = OrganData.area.id;
            break;
          }
          case 2: {
            // nation
            this.organization_id = OrganData.national.id;
            break;
          }
          case 3: {
            // region
            this.organization_id = OrganData.regional.id;
            break;
          }
          case 1: {
            // local
            this.organization_id = OrganData.local.id;
            break;
          }
        }
      },

      getInform(form) {
        this.form = form;
      },

      getAmount(val) {
        this.amount = val;
      },

      donate(amount) {
        if (!this.amount) return this.$message.warning('Please select or fill in the amount')
        let userInfo = JSON.parse(localStorage.getItem("userInfo"));
        this.$http
          .donate({
            user_id: userInfo.user_id,
            donated_type: this.donated_type,
            donor_organization_id: this.organization_id,
            donation_amount:this.amount,
            amount,
            donor_first_name: this.form.first_name,
            donor_last_name: this.form.last_name,
            donor_email: this.form.email,
            donor_country_id: this.form.country_id,
            // donor_organization_id: userInfo.organization_id,
            donor_organization_param: JSON.stringify(this.donor_organization_param),
            // is_pay_all: 1,
          }).then((res) => {
            if (res.status == 200) {
              if (res.data.is_jump == 1) {
                this.$router.push({
                  path: `/PayMoney`,
                  query: {
                    model_type: 8,
                    id: res.data.order_id,
                  },
                });
              }
            }
          });
      },
    },
  };
</script>
<style lang="scss" scoped>
  .other_donate {
    padding: 20px;

    h3 {
      font-size: 16px;
      color: #333;
      font-weight: 500;
      margin-bottom: 26px;
    }
  }

  .appendAsterisk::after {
    content: "*";
    color: red;
    display: inline;
    margin-left: 5px;
  }

  .self_pre_input {
    >span {
      display: block;
      width: 120px;
      height: 45px;
      line-height: 45px;
      text-align: center;
      background: #f8f8f8;
      color: #999999;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }

    >input#selfInput {
      border-radius: 0;
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
      border-color: #f1f1f1;
    }
  }
</style>